import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-more"
export default class extends Controller {
  static targets = ["showMoreContainer", "showMore", "content", "arrow"];
  connect() {
    if (this.contentTarget.scrollHeight <= this.contentTarget.clientHeight) {
      this.showMoreContainerTarget.classList.add('hidden');
    }
  }

  showToggle() {
    if (this.showMoreTarget.innerText === "Show More") {
      this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + 'px';
      this.showMoreTarget.innerText = "Show Less"
      this.arrowTarget.classList.add("rotate-180")
    }
    else {
      this.contentTarget.style.maxHeight = '60vh';
      this.showMoreTarget.innerText = "Show More"
      this.arrowTarget.classList.remove("rotate-180")
    }
  }
}
