import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";


export default class extends Controller {

  static targets = ['checkmarkCircle', 'checkmarkRectSmall', 'checkmarkRectLarge'];

  connect() {
    gsap.from(this.checkmarkCircleTarget, { duration: 1, scale: 0, rotation: 500, transformOrigin: "center center" });
    gsap.from(this.checkmarkRectSmallTarget, { duration: 0.5, scaleX: 0, delay: 0.5 });
    gsap.from(this.checkmarkRectLargeTarget, { duration: 0.5, scaleX: 0, delay: 0.5 });
  }
}
