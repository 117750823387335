import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use";

// Connects to data-controller="location"
export default class extends Controller {
  static targets = ['countrySelect', 'stateSelect', 'citySelect']

  connect() {
    useDispatch(this);
    this.headers = new Headers();
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    this.headers.append('X-CSRF-Token', csrfToken);
    this.headers.append('Accept', 'application/json');
  }

  onCountrySelect(event) {
    const searchParams = new URLSearchParams();
    searchParams.set('country', event.detail.value);
    fetch('/api/v1/locations/states?' + searchParams.toString(), { headers: this.headers })
      .then(response => response.json())
      .then(response => {
        const options = this.defaultOption('state');
        options.push(...response.data.results.map(result => ({ text: result.label, value: result.value })))
        this.dispatch('state:data', { data: options });

      });
  }

  onStateSelect(event) {
    const searchParams = new URLSearchParams();
    searchParams.set('state', event.detail.value);
    fetch('/api/v1/locations/cities?' + searchParams.toString(), { headers: this.headers })
      .then(response => response.json())
      .then(response => {
        const options = this.defaultOption('city');
        options.push(...response.data.results.map(result => ({ text: result, value: result })))
        this.dispatch('city:data', { data: options });
      });
  }

  createOptionTags(options) {
    return options.map(option => {
      if (option.label) {
        return new Option(option.label, option.value);
      }
      return new Option(option, option);
    })
  }

  clearOptions(selectTarget) {
    while (selectTarget.firstChild) {
      selectTarget.removeChild(selectTarget.lastChild);
    }
  }

  defaultOption(type) {
    return [{ text: 'Select ' + type, value: '' }];
  }
}
