import { Slideover } from "tailwindcss-stimulus-components";
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="slideover"
export default class extends Slideover {
  static targets = ['menu', 'overlay', 'sidebar', 'container']

  connect() {
    super.connect();
    useClickOutside(this, { element: this.containerTarget })
  }

  clickOutside() {
    this.openValue = false;
  }


  async _show() {
    this.sidebarTarget.classList.remove(this.toggleClass)
    this.overlayTarget.classList.remove(this.toggleClass)

    const parent_show_promise = this._dropdownShow()
    const transition_promise = transition(
      this.overlayTarget,
      this._enteringClassList[1],
      this._invisibleClassList[1],
      this._visibleClassList[1]);
    await Promise.all([parent_show_promise, transition_promise])
  }

  async _hide() {
    const parent_show_promise = this._dropdownHide()
    const transition_promise = await transition(
      this.overlayTarget,
      this._leavingClassList[1],
      this._visibleClassList[1],
      this._invisibleClassList[1]);

    await Promise.all([parent_show_promise, transition_promise]);

    this.overlayTarget.classList.add(this.toggleClass)
    this.sidebarTarget.classList.add(this.toggleClass)
  }

  async _dropdownShow(cb) {
    // this.menuTarget.classList.remove(this.toggleClass)
    this.element.setAttribute("aria-expanded", "true")

    await transition(
      this.menuTarget,
      this._enteringClassList[0],
      this._invisibleClassList[0],
      this._visibleClassList[0]);

    if (typeof cb == 'function') {
      await cb()
    }
  }

  async _dropdownHide(cb) {
    await transition(
      this.menuTarget,
      this._leavingClassList[0],
      this._visibleClassList[0],
      this._invisibleClassList[0]);

    if (typeof cb == 'function') {
      await cb()
    }

    this.element.setAttribute("aria-expanded", "false")
    // this.menuTarget.classList.add(this.toggleClass)
  }


}

export async function transition(target, transitionClassList, startClassList, endClassList) {
  transitionClassList.forEach(klass => target.classList.add(klass))
  startClassList.forEach(klass => target.classList.add(klass))
  await nextFrame();
  startClassList.forEach(klass => target.classList.remove(klass))
  endClassList.forEach(klass => target.classList.add(klass))
  await afterTransition(target)
  endClassList.forEach(klass => target.classList.remove(klass))
  transitionClassList.forEach(klass => target.classList.remove(klass))
}

function nextFrame() {
  return new Promise(resolve => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve)
    });
  });
}

function afterTransition(element) {
  return new Promise(resolve => {
    // safari return string with comma separate values
    const computedDuration = getComputedStyle(element).transitionDuration.split(",")[0]
    const duration = Number(computedDuration.replace('s', '')) * 1000;
    setTimeout(() => {
      resolve()
    }, duration)
  });
}

