import { Controller } from "@hotwired/stimulus";
import wrapRange from 'wrap-range-text';

// Connects to data-controller="highlights"

function parents(node) {
  let nodes = [node]
  for (; node; node = node.parentNode) {
    nodes.unshift(node)
  }
  return nodes
};

function commonAncestor(node1, node2) {
  let parents1 = parents(node1)
  let parents2 = parents(node2)

  if (parents1[0] != parents2[0]) return node1;

  for (let i = 0; i < parents1.length; i++) {
    if (parents1[i] != parents2[i]) return parents1[i - 1]
  }
}

// to check if the element contains text or not. Elements like img, ul, ol will return true.
function isTextElementEmpty(node) {
  const childNodes = node.childNodes;
  if (childNodes.length == 0)
    return true;
  if (childNodes[0].textContent == null || childNodes[0].textContent.trim() == '')
    return true;
  return false;
}

function trimPreview(commonAncestorContainerElement, position, keepPrevSibling, keepNextSibling, originalPreview) {

  try {
    let startElement = commonAncestorContainerElement.querySelector(`[data-node-id="${position.startContainerId}"]`)
    let endElement = commonAncestorContainerElement.querySelector(`[data-node-id="${position.endContainerId}"]`)

    let prevSibling = startElement.previousElementSibling

    if (prevSibling) {

      if (isTextElementEmpty(prevSibling)) {
        prevSibling = startElement;
      }

      while (prevSibling.previousElementSibling) {
        prevSibling.previousElementSibling.remove();
      }

      if (!keepPrevSibling && startElement.previousElementSibling != null) {
        startElement.previousElementSibling.remove();
      }
    }

    let startParentElement = startElement.parentElement

    while (startParentElement) {
      if (startParentElement.dataset) {
        if (startParentElement.dataset.nodeId == commonAncestorContainerElement.dataset.nodeId) {
          break;
        }
        while (startParentElement.previousElementSibling) {
          startParentElement.previousElementSibling.remove()
        }
      }
      startParentElement = startParentElement.parentElement
    }

    let nextSibling = endElement.nextElementSibling

    if (nextSibling) {

      if (isTextElementEmpty(nextSibling)) {
        nextSibling = endElement;
      }

      while (nextSibling.nextElementSibling) {
        nextSibling.nextElementSibling.remove()
      }

      if (!keepNextSibling && endElement.nextElementSibling != null) {
        endElement.nextElementSibling.remove();
      }
    }

    let endParentElement = endElement.parentElement

    while (endParentElement) {
      if (endParentElement.dataset) {
        if (endParentElement.dataset.nodeId == commonAncestorContainerElement.dataset.nodeId) {
          break;
        }
        while (endParentElement.nextElementSibling) {
          endParentElement.nextElementSibling.remove()
        }
      }
      endParentElement = endParentElement.parentElement
    }
  } catch (e) {
    return originalPreview;
  }
  return commonAncestorContainerElement;
}

function getValidNode(node) {
  // get parent of "#text" node or "mark" node
  if (node.nodeType === Node.TEXT_NODE || node.nodeName === 'MARK') {
    return getValidNode(node.parentNode);
  }

  return node;
}
export default class extends Controller {
  static targets = ['menu', 'addHighlightBtn', 'deleteHighlightBtn', 'featuredTooltipText'];
  static values = {
    highlightsUrl: String,
  }

  connect() {
    this.topHighlights = {};
    this.userHighlights = {};
    this.lastSelectionValue = '';
    this.csrfToken = document.querySelector("[name='csrf-token']").content;
    this.headers = new Headers();
    this.headers.append('X-CSRF-Token', this.csrfToken);
    this.headers.append('Accept', 'application/json');

    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      const sectionId = searchParams.get('h_id');
      const targetElement = sectionId ? document.querySelector(`[data-node-id="${sectionId}"]`) : null;
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 64,
          behavior: "smooth"
        });
      }
    }


    if (this.hasMenuTarget) {
      this.element.addEventListener('mouseup', this.onSelect);
      document.addEventListener('keyup', this.onKeyUp);
      // Add `selectionchange` event listner on touch devices
      if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        document.addEventListener('selectionchange', this.onSelect);
      }
      // to show menu on hover for featured highlight
      this.menuTarget.addEventListener('mouseenter', () => window.menuHover = true);
      this.menuTarget.addEventListener('mouseleave', () => {
        window.menuHover = false;
        setTimeout(() => this.hideMenu(), 250);
      });
    }

    // fetch and display highlights for current user
    fetch(this.highlightsUrlValue, { headers: this.headers })
      .then(response => response.json())
      .then((response => {
        if (response && response.user_highlights) {
          response.user_highlights.forEach(highlight => {
            try {
              this.displayHighlight(highlight);
            }
            catch (err) {
              console.log('Error occured while displaying highlight', highlight);
            }
          });
        }
        if (response && response.top_highlights) {
          response.top_highlights.forEach(highlight => {
            try {
              this.displayHighlight(highlight, true);
            }
            catch (err) {
              console.log('Error occured while displaying highlight', highlight);
            }
          });
        }
        if (response && response.id) {
          this.displayHighlight(response);
        }
      }).bind(this));
  }

  disconnect() {
    this.element.removeEventListener('mouseup', this.onSelect);
    document.removeEventListener('selectionchange', this.onSelect);
    document.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyUp = event => {
    // trigger selection when text is selected using Shift key
    const key = event.code || event.keyCode;
    if (key == 16 || key.toLowerCase().includes('shift')) this.onSelect(event);
  }

  onSelect = event => {
    const selection = window.getSelection();
    if (event.type === 'selectionchange' && this.lastSelectionValue === selection.toString().trim()) {
      return;
    }
    this.lastSelectionValue = selection.toString().trim();
    if (selection.toString().trim() === '' || selection.rangeCount !== 1) {
      this.menuTarget.classList.add('invisible', 'opacity-0');
      return;
    }

    const range = selection.getRangeAt(0);
    if (!this.isValidRange(range)) {
      this.menuTarget.classList.add('invisible', 'opacity-0');
      return;
    }

    this.showMenu(range);
  }

  isValidRange(range) {
    const commonAncestorContainerElement = getValidNode(range.commonAncestorContainer);
    const startContainerElement = getValidNode(range.startContainer);
    const endContainerElement = getValidNode(range.endContainer);
    let overlapsOtherHighlights = false;
    document.querySelectorAll('mark').forEach(node => {
      if (range.intersectsNode(node)) {
        overlapsOtherHighlights = true;
      }
    })
    return this.element.contains(range.commonAncestorContainer) &&
      !!commonAncestorContainerElement.dataset.nodeId &&
      !!startContainerElement.dataset.nodeId &&
      !!endContainerElement.dataset.nodeId &&
      range.startContainer.nodeType === Node.TEXT_NODE &&
      range.endContainer.nodeType === Node.TEXT_NODE &&
      !overlapsOtherHighlights &&
      !range.collapsed
  }

  onHighlightClick(event) {
    this.showMenu(event.currentTarget);
  }

  showMenu = (highlightElement) => {
    if (this.hasDeleteHighlightBtnTarget && this.hasAddHighlightBtnTarget) {
      if (highlightElement.dataset && highlightElement.dataset.highlightId && highlightElement.classList.contains('highlight')) {
        this.addHighlightBtnTarget.classList.add('hidden');
        this.deleteHighlightBtnTarget.classList.remove('hidden');
        this.deleteHighlightBtnTarget.setAttribute('data-highlights-url-param', this.userHighlights[highlightElement.dataset.highlightId].url);
        this.currentHighlightText = this.userHighlights[highlightElement.dataset.highlightId].text_content;
      }
      else {
        this.addHighlightBtnTarget.classList.remove('hidden');
        this.deleteHighlightBtnTarget.classList.add('hidden');
      }
    }
    if (this.hasFeaturedTooltipTextTarget) {
      if (highlightElement.dataset && highlightElement.dataset.count && highlightElement.classList.contains('top-highlight')) {
        this.featuredTooltipTextTarget.innerText = `Highlighted ${highlightElement.dataset.count} times`
        this.featuredTooltipTextTarget.classList.remove('hidden');
        this.currentHighlightText = this.topHighlights[highlightElement.dataset.highlightId].text_content;
        if (this.hasAddHighlightBtnTarget) {
          this.addHighlightBtnTarget.setAttribute('data-highlights-id-param', highlightElement.dataset.highlightId);
          this.addHighlightBtnTarget.setAttribute('data-action', 'click->highlights#onFeatureHighlight');
        }
      }
      else {
        this.featuredTooltipTextTarget.classList.add('hidden');
        if (this.hasAddHighlightBtnTarget) {
          this.addHighlightBtnTarget.setAttribute('data-action', 'click->highlights#onHighlight');
        }
      }
    }
    const { x, y, width } = highlightElement.getBoundingClientRect();
    const menuOffset = (document.querySelector('header.sticky') ? 64 : 0) + 55;
    this.menuTarget.style.left = (x + width / 2) + 'px';
    this.menuTarget.style.top = (y + window.scrollY - menuOffset) + 'px';
    this.menuTarget.classList.remove('invisible', 'opacity-0');
  }

  hideMenu = () => {
    if (!window.menuHover) {
      this.menuTarget.classList.add('invisible', 'opacity-0');
    }
  }

  shareOnTwitter() {
    let selectedText = window.getSelection().toString().trim();
    if (selectedText === '') {
      selectedText = this.currentHighlightText;
    }
    const shareText = `${selectedText}\n\n- buildd\n\nRead full article:\n`
    const shareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(shareText)}`;
    window.open(shareURL, '_blank');
  }

  onHighlight() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    let keepPrevSibling = false;
    let keepNextSibling = false;

    if (range.collapsed || selection.rangeCount !== 1) return;

    this.menuTarget.classList.add('invisible', 'opacity-0');

    const { commonAncestorContainer, startContainer, endContainer, startOffset, endOffset } = range;
    const startContainerElement = getValidNode(startContainer);
    const endContainerElement = getValidNode(endContainer);
    const absoluteStartOffset = this.calculateAbsoluteOffset(startContainerElement, startContainer, startOffset);
    const absoluteEndOffset = this.calculateAbsoluteOffset(endContainerElement, endContainer, endOffset);

    const originalCommonAncestorContainerElement = getValidNode(commonAncestorContainer);
    let commonAncestorContainerElement = originalCommonAncestorContainerElement
    let finalPreview = commonAncestorContainerElement.outerHTML

    if (absoluteStartOffset >= 0 && absoluteStartOffset <= 40) {
      if (commonAncestorContainerElement == startContainerElement) {
        commonAncestorContainerElement = commonAncestorContainerElement.parentNode;
      }
      keepPrevSibling = true;
    }

    const cleanText = endContainerElement.outerHTML.replace(/<\/?[^>]+(>|$)/g, "");
    const endOffsetLengthDiff = absoluteEndOffset - cleanText.length;

    if (endOffsetLengthDiff >= 0 && endOffsetLengthDiff <= 40) {
      if (commonAncestorContainerElement != endContainerElement.parentNode) {
        commonAncestorContainerElement = getValidNode(commonAncestor(commonAncestorContainerElement, endContainerElement.parentNode))
      }
      keepNextSibling = true;
    }

    const position = {
      commonAncestorContainerId: originalCommonAncestorContainerElement.dataset.nodeId,
      startContainerId: startContainerElement.dataset.nodeId,
      endContainerId: endContainerElement.dataset.nodeId,
      startOffset: absoluteStartOffset,
      endOffset: absoluteEndOffset,
    }

    if (commonAncestorContainerElement != startContainerElement && commonAncestorContainerElement != endContainerElement) {
      finalPreview = trimPreview(commonAncestorContainerElement.cloneNode(true), position, keepPrevSibling, keepNextSibling, originalCommonAncestorContainerElement).outerHTML;
    }

    this.postHighlight(range, selection.toString(), finalPreview, position);
  }

  calculateAbsoluteOffset(parent, element, offset) {
    if (parent === element) {
      return offset;
    }
    const indexOfElement = Array.from(parent.childNodes).indexOf(element);
    if (indexOfElement === 0) {
      return offset;
    }

    const childNodes = this.getTextChildNodes(parent);
    const newIndexOfElement = childNodes.indexOf(element);
    let newOffset = 0;
    for (let index = 0; index < newIndexOfElement; index++) {
      const child = childNodes[index];
      if (child.nodeType === Node.TEXT_NODE) {
        newOffset += child.length;
      }
    }
    return newOffset + offset;
  }

  getTextChildNodes(node) {
    const textNodes = [];
    node.childNodes.forEach(child => {
      if (child.nodeType !== Node.TEXT_NODE) {
        textNodes.push(...this.getTextChildNodes(child));
      }
      else {
        textNodes.push(child);
      }
    });
    return textNodes;
  }

  onFeatureHighlight({ params: { id } }) {
    this.topHighlights[id].wrap.unwrap();
    const range = this.getRangeFromPosition(this.topHighlights[id].position)
    this.postHighlight(range, this.topHighlights[id].text_content, this.topHighlights[id].preview, this.topHighlights[id].position);
  }

  postHighlight(range, selectedText, preview, position) {
    const formData = new FormData();
    formData.append('authenticity_token', this.csrfToken)
    formData.append('highlight[text_content]', selectedText);
    formData.append('highlight[preview]', preview)
    formData.append('highlight[position]', JSON.stringify(position))

    fetch(this.highlightsUrlValue, {
      method: 'POST',
      body: formData,
      headers: this.headers
    })
      .then(res => res.json())
      .then((highlight => {
        if (highlight.id) {
          this.wrapHighlightText(highlight, range);
        }
      }).bind(this))
  }

  deleteHighlight({ params: { url } }) {
    this.menuTarget.classList.add('invisible', 'opacity-0');
    fetch(url, {
      method: 'DELETE',
      headers: this.headers
    })
      .then(res => res.json())
      .then((highlight => {
        if (this.userHighlights[highlight.id] && this.userHighlights[highlight.id].wrap) {
          this.userHighlights[highlight.id].wrap.unwrap();
        }
      }).bind(this))
  }

  displayHighlight = (highlight, featured = false) => {
    const newRange = this.getRangeFromPosition(highlight.position);
    this.wrapHighlightText(highlight, newRange, featured);
  }

  getRangeFromPosition(position) {
    const startElement = this.getNodeById(position.startContainerId);
    const endElement = this.getNodeById(position.endContainerId);
    const newRange = new Range();
    const [startElementChildIndex, relativeStartOffset] = this.getChildIndexAndRelativeOffset(startElement, position.startOffset);
    newRange.setStart(startElement.childNodes[startElementChildIndex], relativeStartOffset);
    const [endElementChildIndex, relativeEndOffset] = this.getChildIndexAndRelativeOffset(endElement, position.endOffset);
    newRange.setEnd(endElement.childNodes[endElementChildIndex], relativeEndOffset);
    return newRange;
  }

  getChildIndexAndRelativeOffset(element, absoluteOffset) {
    const children = this.getTextChildNodes(element);
    for (let index = 0; index < children.length; index++) {
      let child = children[index];
      if (absoluteOffset <= child.length) {
        const relativeIndex = Array.from(element.childNodes).indexOf(child);
        return [relativeIndex, absoluteOffset];
      }
      absoluteOffset -= child.length;
    }
  }

  wrapHighlightText(highlight, range, featured) {
    const wrapper = this.createHighlightWrapper(highlight, featured);
    const wrap = wrapRange(wrapper, range);
    if (featured) {
      this.topHighlights[highlight.id] = { ...highlight, wrap };
    }
    else {
      this.userHighlights[highlight.id] = { ...highlight, wrap };
    }
    wrap.nodes.forEach(node => {
      node.addEventListener('mouseenter', (event) => {
        wrap.nodes.forEach(wrappedNode => wrappedNode.classList.add('active'));
        if (featured) {
          this.showMenu(event.currentTarget);
          window.menuHover = true;
        }
      })
      node.addEventListener('mouseleave', () => {
        wrap.nodes.forEach(wrappedNode => wrappedNode.classList.remove('active'));
        if (featured) {
          window.menuHover = false;
          setTimeout(() => this.hideMenu(), 250);

        };
      })
    })
  }

  getNodeById(id) {
    return document.querySelector(`[data-node-id="${id}"]`);
  }

  createHighlightWrapper(highlight, featured) {
    const wrapper = document.createElement('mark');
    if (featured) {
      wrapper.classList.add('top-highlight');
      wrapper.setAttribute('data-highlight-id', highlight.id);
      wrapper.setAttribute('data-count', highlight.count);
    }
    else {
      wrapper.setAttribute('data-highlights-target', 'highlight');
      wrapper.setAttribute('data-highlight-id', highlight.id);
      if (this.hasMenuTarget) {
        wrapper.setAttribute('data-action', 'click->highlights#onHighlightClick');
      }
      wrapper.classList.add('highlight');
    }
    return wrapper;
  }
}
