import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pending-requests"
export default class extends Controller {
  static targets = ['indicator']
  static values = {
    url: String,
  }

  connect() {
    fetch(this.urlValue).then(response => response.json()).then(this.handleReponse.bind(this)).catch(console.log);
  }

  handleReponse(response) {
    if (response.data.total > 0) {
      this.indicatorTarget.classList.remove('hidden');
      this.indicatorTarget.innerText = response.data.total;
    }
  }
}
