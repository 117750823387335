import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["track", "back", "next"];

  static values = {
    slide: Number
  }

  disableClasses = ['cursor-not-allowed', 'opacity-30'];

  connect() {
    if (this.hasNextTarget && this.hasBackTarget) {
      if (this.trackTarget.scrollWidth <= this.trackTarget.clientWidth) {
        this.nextTarget.classList.remove('md:flex');
        this.backTarget.classList.remove('md:flex');
      }
      else {
        this.disableTarget(this.backTarget);
      }
      this.trackTarget.addEventListener('scroll', this.updateScrollButtons.bind(this));
    }
  }

  disconnect() {
    if (this.hasNextTarget && this.hasBackTarget) {
      this.trackTarget.removeEventListener('scroll', this.updateScrollButtons.bind(this));
    }
  }

  scroll({ params: { direction } }) {
    this.trackTarget.scrollTo({
      left: direction === 'back' ?
        this.trackTarget.scrollLeft - this.slideValue :
        this.trackTarget.scrollLeft + this.slideValue,
      behavior: "smooth"
    });
  }

  updateScrollButtons() {
    if (this.trackTarget.scrollLeft === 0) {
      this.disableTarget(this.backTarget);
    }
    else {
      this.enableTarget(this.backTarget);
    }
    if (this.trackTarget.scrollLeft === this.trackTarget.scrollWidth - this.trackTarget.clientWidth) {
      this.disableTarget(this.nextTarget);
    }
    else {
      this.enableTarget(this.nextTarget);
    }
  }

  disableTarget(target) {
    target.disabled = true;
    target.classList.add(...this.disableClasses);
  }

  enableTarget(target) {
    target.disabled = false;
    target.classList.remove(...this.disableClasses);
  }

  autoScroll(event) {
    const currentTabIndex = event.detail.tabIndex;
    this.trackTarget.scrollTo({
      left: this.slideValue * currentTabIndex,
      behavior: "smooth"
    });
  }
}
