import { Tabs } from "tailwindcss-stimulus-components";
import { useIntersection, useDispatch } from "stimulus-use";

export class Controller extends Tabs {
  static targets = ['progressPath', 'tabContainer'];

  connect() {
    useIntersection(this, {
      element: this.tabContainerTarget,
      threshold: 1
    });
    useDispatch(this);
    this.numberOfTabs = this.tabTargets.length;
    this.stopped = false;
    super.connect();
  }

  change(event) {
    super.change(event);
    this.stopAutoswitch();
  }

  appear() {
    if (!this.stopped && !this.interval) {
      this.dispatch('appear');
      this.startProgressBarTransition();
      this.interval = setInterval(this.switchTab.bind(this), 7500);
    }
  }

  switchTab() {
    this.index = this.index < this.numberOfTabs - 1 ? this.index + 1 : 0;
    this.dispatch('auto-switch', { tabIndex: this.index });
    this.startProgressBarTransition();
    this.showTab();
  }

  stopAutoswitch() {
    this.stopped = true;
    clearInterval(this.interval);
    this.progressPathTargets.forEach(progressPath => {
      progressPath.classList.add('invisible');
    });
  }

  scrollIntoView() {
    window.scrollTo({
      top: this.element.offsetTop - 64,
      behavior: "smooth"
    });
  }

  startProgressBarTransition() {
    this.progressPathTargets.forEach(((progressPath, index) => {
      if (index === this.index) {
        progressPath.classList.remove('invisible');
        progressPath.classList.add(`[stroke-dashoffset:0]`);
      }
      else {
        progressPath.classList.add('invisible');
        progressPath.classList.remove(`[stroke-dashoffset:0]`);
      }
    }).bind(this));
  }
}
