import { Controller } from "@hotwired/stimulus"
import { useDispatch } from 'stimulus-use'

// Connects to data-controller="comment-form"
export default class extends Controller {
  static targets = ['actionBtn'];

  connect() {
    useDispatch(this);
  }

  onFocus() {
    this.actionBtnTarget.classList.replace('hidden', 'flex');
    this.dispatch("focus");
  }

  onBlur() {
    this.dispatch("blur");
  }
}
