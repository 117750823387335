import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'


// Connects to data-controller="share-button"
export default class extends Controller {
  async onClick({ params: { path } }) {
    await post(path, { responseKind: 'turbo-stream' })
  }
}
