import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gfs-result"
export default class extends Controller {
  // connect() {
  //   history.pushState(null, '', `/search/${this.slug}`)
  // }

  // disconnect() {
  //   history.back();
  // }

  get slug() {
    return this.data.get('slug') || '';
  }
}
