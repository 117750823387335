import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to"
export default class extends Controller {
  static targets = ["container"];
  static values = {
    offset: Number
  }

  connect() {
    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      const sectionId = searchParams.get('sec_id');
      const targetElement = sectionId ? document.querySelector(`#${sectionId}`) : null;
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - this.offsetValue,
          behavior: "smooth"
        });
      }
    }
  }

  scroll(event) {
    event.preventDefault();
    const { id, offset } = event.params;
    let currentContainer = id ? document.querySelector(id) : this.containerTarget;
    window.scrollTo({
      top: currentContainer.offsetTop - (offset || this.offsetValue || 0),
      behavior: "smooth"
    })
  }
}
