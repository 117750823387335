import { Dropdown } from "tailwindcss-stimulus-components"

// Connects to data-controller="module-dropdown"
export default class ModuleDropdown extends Dropdown {

    static targets = ['moduleDropdownArrow', 'moduleAboutFigures'];

    openValueChanged() {
        if (this.data.get('isUnlocked') === 'true') {
            super.openValueChanged();
            if (this.openValue) {
                this.moduleDropdownArrowTarget.classList.remove('-rotate-90')
                this.moduleAboutFiguresTarget.classList.add('md:hidden')
            } else {
                this.moduleDropdownArrowTarget.classList.add('-rotate-90')
                this.moduleAboutFiguresTarget.classList.remove('md:hidden')
            }
        }
    }
}