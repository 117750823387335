import { Tabs } from "tailwindcss-stimulus-components"

// Connects to data-controller="accordian"
export default class extends Tabs {
  static targets = ['arrow'];

  change(event) {
    event.preventDefault();

    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      this.index = event.currentTarget.dataset.index

      // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id) {
      this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

      // Check if target is current tab
    } else if (this.tabTargets.indexOf(event.currentTarget) == this.index) {
      this.index = null

      // Otherwise, use the index of the current target
    } else {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    if (this.hasArrowTarget) {
      this.arrowTargets.forEach((arrowTarget, index) => {
        if (index == this.index) {
          arrowTarget.classList.add('rotate-180');
        } else {
          arrowTarget.classList.remove('rotate-180');
        }
      });
      const offsetTop = this.tabTargets[this.index].getBoundingClientRect().top + document.documentElement.scrollTop;
      window.scrollTo({
        top: offsetTop - 84,
        behavior: "smooth"
      });
    }

    window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }

  get index() {
    return this.data.get('index') == null ? null : parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', (value >= 0 ? value : 0))
    this.showTab()
  }
}
