import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["form"];

  play(event) {
    event.preventDefault();
    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit');
      this.formTarget.remove();
    }
  }
}
