import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap";

// Connects to data-controller="text-change-animation"
export default class extends Controller {

  connect() {
    this.counter = 1
    this.element.innerText = this.textValues[0];
    if (this.typing) {
      setInterval(this.eraseText.bind(this), 3000);
    }
    else {
      setInterval(this.hideText.bind(this), 3000);
    }
  }

  eraseText() {
    const self = this;
    gsap.fromTo(this.element, {
      width: `${this.element.scrollWidth}px`,
    }, {
      width: 0,
      ease: `steps (${this.element.innerText.length})`,
      duration: 1,
      onComplete: this.typeText.bind(this)
    });
  }

  typeText() {
    this.element.innerText = this.textValues[this.counter];
    this.counter++;
    if (this.counter >= this.textValues.length) { this.counter = 0; }
    gsap.fromTo(this.element, {
      width: 0,
    }, {
      width: `${this.element.scrollWidth}px`,
      ease: `steps (${this.element.innerText.length})`,
      duration: 1,
    });
  }

  hideText() {
    const self = this;
    gsap.to(this.element, {
      opacity: 0,
      onComplete: this.changeText.bind(this)
    });
  }

  changeText() {
    this.element.innerText = this.textValues[this.counter];
    this.counter++;
    if (this.counter >= this.textValues.length) { this.counter = 0; }
    gsap.to(this.element, { opacity: 1 });
  }

  get textValues() {
    const text = this.data.get('textValues') || '';
    return text.split(',').filter(value => value);
  }

  get typing() {
    const text = this.data.get('typing') || 'false';
    return text == 'true';
  }
}
