import SliderController from "./slider_controller"

export default class extends SliderController {

  initialize() {
    super.initialize();

    this.sliderCount = Math.ceil(parseFloat(this.countValue) / 2);
    this.indicatorWidth = this.indicatorTarget.parentElement.clientWidth / this.sliderCount;
    this.indicatorTarget.style.width = `${this.indicatorWidth}px`;
    this.showCurrentSlide();
  }

  showCurrentSlide() {
    super.showCurrentSlide();

    // indicator
    this.indicatorTarget.style.left = `${this.index * this.indicatorWidth}px`;

    // if total testimonials are odd
    // shift last testimonial to center
    if (this.countValue & 1) {
      this.trackTarget.children[this.trackTarget.children.length - 1].classList.add("md:ml-[50%]");
      this.trackTarget.children[this.trackTarget.children.length - 1].classList.add("md:translate-x-[-50%]");
    }

  }

  next() {

    if (this.index === this.sliderCount - 1) {
      // Reached the last slide, so loop back to the first slide
      this.index = 0;
    } else {
      this.index++;
    }
    this.showCurrentSlide();
  }

  back() {
    if (this.index === 0) {
      // Reached the first slide, so loop back to the last slide
      this.index = this.sliderCount - 1;
    } else {
      this.index--;
    }
    this.showCurrentSlide();
  }
}