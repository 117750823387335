import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scrape"
export default class extends Controller {

  static targets = ["input", "loader", "result", "resultcontent", "textloader", "textloadercontent"];

  phrases = [
    "Scraping the data....",
    "Chunking the scraped data....",
    "Vectorizing the chunks....",
    "Uploading vectors to Pinecone...."
  ];

  connect() {
    this.csrfToken = document.querySelector("[name='csrf-token']").content;
  }

  async scrape() {
    const url = this.inputTarget.value;

    // Display loader while waiting for response
    this.showLoader();
    this.fadeText();

    try {
      const response = await fetch("/search_gpt/scrape", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        },
        body: JSON.stringify({ url: url }),
      });

      const data = await response.json();
      this.setResult(data);
    } catch (error) {
      console.error("An error occurred:", error);
      this.setResult("Error fetching data.");
    } finally {
      // Hide loader once response is received
      this.hideLoader();
    }
  }

  showLoader() {
    this.loaderTarget.classList.remove("hidden");
    this.resultTarget.classList.add("hidden");
  }

  fadeText() {
    this.textloaderTarget.classList.remove("hidden");
    let currentPhraseIndex = 0;
    let firstTime = true;

    const fadeLoop = () => {
      if (currentPhraseIndex >= this.phrases.length) {
        this.textloaderTarget.classList.add("hidden"); // Hide the text loader once fade text is completed
        return;
      }

      const fadeDuration = firstTime ? 1000 : 1750; // No fade-in duration for the first time
      this.textloadercontentTarget.textContent = this.phrases[currentPhraseIndex];
      this.textloadercontentTarget.classList.add("fade-in");

      setTimeout(() => {
        this.textloadercontentTarget.classList.remove("fade-in");
        this.textloadercontentTarget.classList.add("fade-out");
        currentPhraseIndex++;

        setTimeout(() => {
          this.textloadercontentTarget.classList.remove("fade-out");
          fadeLoop();
        }, 500); // Fade out duration
      }, fadeDuration); // Fade in duration

      firstTime = false;
    };

    fadeLoop();
  }

  hideLoader() {
    this.loaderTarget.classList.add("hidden");
  }

  setResult(result) {
    this.resultTarget.classList.remove("hidden");
    this.resultcontentTarget.innerHTML = result["data"]["article_content"];
  }

}