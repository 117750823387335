import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js';
import { useDispatch } from "stimulus-use";

// Connects to data-controller="search-box"
export default class extends Controller {
  static targets = ['placeholder', 'intentTypeSection', 'topicSection', 'searchSection', 'resultsSection', 'intentTypeInput', 'topicInput', 'searchInput'];
  static values = {
    intentType: String,
    topic: String,
    searchQuery: String,
    searchStarted: Boolean,
  };

  connect() {
    this.searchStarteValue = false;
    useDispatch(this);
  }

  onSelectInput(event) {
    if (event.detail.topic) {
      this.topicValue = event.detail.topic;
    } else if (event.detail.intentType) {
      this.intentTypeValue = event.detail.intentType;
    }
  }

  clear(event) {
    event.preventDefault();
    this.topicValue = this.intentTypeValue = '';
    this.searchInputTarget.value = '';
    this.resultsSectionTarget.innerHTML = null;
    this.searchStartedValue = false;
    this.dispatch('clear');
  }

  topicValueChanged() {
    this.topicInputTarget.value = this.topicValue;
    if (this.topicValue) {
      this.searchSectionTargets.forEach(target => target.classList.remove('hidden'));
      this.searchInputTarget.focus();
    } else {
      this.searchSectionTargets.forEach(target => target.classList.add('hidden'));
    }
  }

  onSearchStart() {
    this.searchStartedValue = true;
  }

  searchStartedValueChanged() {
    if (this.searchStartedValue) {
      this.placeholderTarget.classList.add('hidden');
      this.intentTypeSectionTarget.classList.remove('hidden');
    } else {
      this.placeholderTarget.classList.remove('hidden');
      this.intentTypeSectionTarget.classList.add('hidden');

    }
  }

  async intentTypeValueChanged() {
    this.intentTypeInputTarget.value = this.intentTypeValue;
    if (this.intentTypeValue) {
      await post('/search/topics', { body: { intent_type: this.intentTypeValue }, responseKind: 'turbo-stream' });
    } else {
      this.topicSectionTarget.innerHTML = null;
    }
  }
}
