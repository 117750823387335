import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown-timer"
export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'seconds']

  connect() {
    this.interval = setInterval(this.updateTimer.bind(this), 1000);
  }

  updateTimer() {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = this.endDate - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the elements
    this.daysTarget.innerHTML = this.addPaddingZero(days);
    this.hoursTarget.innerHTML = this.addPaddingZero(hours);
    this.minutesTarget.innerHTML = this.addPaddingZero(minutes);
    this.secondsTarget.innerHTML = this.addPaddingZero(seconds);

    // If the count down is finished, set everything to 0
    if (distance < 0) {
      clearInterval(this.interval);
      this.daysTarget.innerHTML = '00';
      this.hoursTarget.innerHTML = '00';
      this.minutesTarget.innerHTML = '00';
      this.secondsTarget.innerHTML = '00';
    }
  }

  addPaddingZero(number) {
    const numberString = number.toString();
    return this.shouldPadZero && numberString.length == 1 ? '0' + numberString : numberString;
  }

  get endDate() {
    return new Date(this.data.get('endDate'));
  }

  get shouldPadZero() {
    const padZero = this.data.get('padZero') || 'false';
    return padZero === 'true';
  }
}
