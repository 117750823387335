import ScrollTrigger from "./scroll_trigger_controller";
import Rails from '@rails/ujs';

// Connects to data-controller="read-trigger"
export default class extends ScrollTrigger {
  action() {
    Rails.fire(this.element, 'submit');
    this.element.remove();
  }
}
