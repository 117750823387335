import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-trigger"
export default class extends Controller {
  static values = {
    scrollPercent: Number,
  }

  initialize() {
    this.boundedTrigger = this.trigger.bind(this);
  }

  connect() {
    window.addEventListener('scroll', this.boundedTrigger);
  }

  trigger() {
    const scrollPercent = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
    if (scrollPercent > this.scrollPercentValue) {
      this.action();
      window.removeEventListener('scroll', this.boundedTrigger);
    }
  }

  // override action funtion 
  action() {
    console.log('Triggered action');
  }
}
