import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lessons-mistakes-toggle"
export default class extends Controller {
  static targets = ['checkbox'];
  static values = {
    isMistakeSelected: { type: Boolean, default: false }
  }

  toggle() {
    this.isMistakeSelectedValue = !this.isMistakeSelectedValue;
  }

  isMistakeSelectedValueChanged() {
    let arr = window.location.href.split('/')
    if (this.isMistakeSelectedValue) {
      this.element.classList.add('dark');
      arr[3] = "mistakes";
      window.history.replaceState({}, '', arr.join("/"));
    } else {
      this.element.classList.remove('dark');
      arr[3] = "lessons";
      window.history.replaceState({}, '', arr.join("/"));
    }
  }
}
