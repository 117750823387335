import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collections-card-slider"
export default class extends Controller {
  static targets = ["track", "indicator", "rank", "trackTitle"]

  indicatorOnClick(event) {
    let clickedIndex = event.target.getAttribute("data-index");
    this.trackTarget.style = `transform: translateX(-${100 * clickedIndex}%);`;
    this.trackTitleTarget.style = `transform: translateX(-${100 * clickedIndex}%);`;

    Array.from(this.indicatorTarget.children).forEach(child => {
      if (child.getAttribute("data-index") == clickedIndex) {
        child.classList.replace("bg-gray-400", "bg-white");
      } else {
        child.classList.replace("bg-white", "bg-gray-400");
      }
    })

    this.rankTarget.innerHTML = `${++clickedIndex}`;
  }
}
