import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="button-loader"
export default class extends Controller {
  static targets = ["button"];

  onClick(event) {
    const loader = document.createElement('i');
    loader.classList.add('ri-loader-4-line', 'animate-spin', ...this.customClasses);

    this.buttonTargets.forEach((buttonTarget => {
      if (buttonTarget === event.submitter) {
        buttonTarget.innerHTML = '';
        buttonTarget.appendChild(loader);
      }
      window.setTimeout((() => {
        buttonTarget.disabled = true;
      }).bind(this), 0)
    }).bind(this));
  }

  get customClasses() {
    return this.data.get('custom-classes')?.split(' ') || [];
  }
}
