import { Tabs } from "tailwindcss-stimulus-components"

export class Controller extends Tabs {
  static targets = ['tabContent', 'mobilePanel'];

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      const mobilePanel = this.mobilePanelTargets[index]

      if (index === this.index) {
        panel.classList.remove('md:hidden');
        mobilePanel.classList.remove('hidden');
        tab.classList.remove(...this.inactiveTabClasses);
        tab.classList.add(...this.activeTabClasses);
        const tabContentElement = this.tabContentTargets[index];
        if (tabContentElement != null) {
          tabContentElement.style = `max-height: ${tabContentElement.scrollHeight}px; visibility: visible;`;
        }
        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (tab.id) {
          location.hash = tab.id;
        }
      } else {
        panel.classList.add('md:hidden');
        mobilePanel.classList.add('hidden');
        tab.classList.remove(...this.activeTabClasses);
        tab.classList.add(...this.inactiveTabClasses);
        const tabContentElement = this.tabContentTargets[index];
        if (tabContentElement != null) {
          tabContentElement.style = `max-height: 0px; visibility: hidden;`;
        }
      }
    })
  }
}
