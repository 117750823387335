import { Controller } from "@hotwired/stimulus"
import WZoom from 'vanilla-js-wheel-zoom';

// Connects to data-controller="image-zoom"
export default class extends Controller {
  connect() {
    this.zoom = WZoom.create(this.element, {
      minScale: 1,
      maxScale: 2,
      disableWheelZoom: true
    });
  }

  disconnect() {
    this.zoom.destroy();
  }
}
