import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ['progressBar', 'progressText']

  updateProgress(event) {
    const radius = this.data.get('radius');
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - event.detail.progress / 100 * circumference;
    this.progressBarTarget.style.strokeDashoffset = offset;
    this.progressTextTarget.innerText = parseInt(event.detail.progress) + '%';
  }
}
