import { Modal } from "tailwindcss-stimulus-components";

export class Controller extends Modal {
  connect() {
    super.connect();

    if (this.showOnLoad && !window.location.hash) {
      let event = new CustomEvent("open");
      this.element.dispatchEvent(event);
    }
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Add classes to body to fix its position
    document.body.classList.add('inset-x-0', 'overflow-hidden');

    if (this.restoreScrollValue) {
      // Save the scroll position
      this.saveScrollPosition();

      // Add negative top position in order for body to stay in place
      document.body.style.top = `-${this.scrollPosition}px`;
    }
  }

  async downloadAndclose(event) {
    super.close(event);
    await this.downloadImage(event.target.href, event.params.filename)
  }

  async downloadImage(imageSrc, filename) {
    const image = await fetch(imageSrc);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  get showOnLoad() {
    return this.data.get('showOnLoad') === 'true';
  }
}
