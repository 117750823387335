import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDispatch } from "stimulus-use";

// Connects to data-controller="immersive-page"
export default class extends Controller {
  static targets = ['slide', 'slideContent', 'slideTimelinePoint', 'slideTimelineProgress', 'scrollText', 'lesson', 'backdrop', 'backBtn', 'nextBtn'];
  static values = {
    currentSlideIndex: Number,
  }

  initialize() {
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  connect() {
    useDispatch(this);
    this.totalSlides = this.slideTargets.length
    this.currentSlideIndexValue = 0;

    window.addEventListener('keyup', this.onKeyUp);

    gsap.defaults({ overwrite: 'auto', duration: 0.3 });

    // stretch out the body height according to however many sections there are. 
    gsap.set("body", { height: (this.totalSlides * this.slideTarget.clientHeight) + (this.slideTarget.clientHeight / 2) + "px" });

    this.slideScrollTriggers = this.slideTargets.map((_, index) => {
      return ScrollTrigger.create({
        start: () => (index - 0.5) * window.innerHeight,
        end: () => (index + 0.5) * window.innerHeight,
        onEnter: () => this.onEnter(index),
        onEnterBack: () => this.onEnterBack(index),
        onLeave: () => this.onLeave(index),
        onLeaveBack: () => this.onLeaveBack(index),
        onUpdate: self => this.onUpdate(self, index),
      });
    });
  }

  disconnect() {
    window.removeEventListener('keyup', this.onKeyUp);
    this.slideScrollTriggers.forEach(trigger => trigger.kill());
  }

  onEnter(index) {
    this.currentSlideIndexValue = index;
    gsap.to(this.slideTargets[index], { scale: 1, autoAlpha: 1 });
    gsap.to(this.slideContentTargets[index], { x: 0, autoAlpha: 1 })
    if (index > 0 && index < this.totalSlides - 1) {
      const timeline = gsap.timeline();
      if (index > 1) {
        timeline.to(this.slideTimelineProgressTargets[index - 2], { width: '100%', duration: 0.3 });
      }
      timeline.to(this.slideTimelinePointTargets[index - 1], { color: '#8A5CF6' });
    }
  }

  onLeave(index) {
    if (index !== this.totalSlides - 1) {
      gsap.to(this.slideTargets[index], { scale: 1.25, autoAlpha: 0 })
      gsap.to(this.slideContentTargets[index], { y: 100, autoAlpha: 0 })
    }
    if (index === 0) {
      gsap.to(this.scrollTextTarget, { y: 10, autoAlpha: 0 })
    }
    this.hideLesson(index)
  }

  onEnterBack(index) {
    this.currentSlideIndexValue = index;
    gsap.to(this.slideTargets[index], { scale: 1, autoAlpha: 1 });
    gsap.to(this.slideContentTargets[index], { y: 0, autoAlpha: 1 })
    if (index === 0) {
      gsap.to(this.scrollTextTarget, { y: 0, autoAlpha: 1 })
    }
    this.showLesson(index);
  }

  onLeaveBack(index) {
    gsap.to(this.slideTargets[index], { scale: 1.25, autoAlpha: 0 })
    gsap.to(this.slideContentTargets[index], { x: 160, autoAlpha: 0 })
    if (index < this.totalSlides - 1) {
      const timeline = gsap.timeline();
      timeline.to(this.slideTimelinePointTargets[index - 1], { color: '#FFFFFF' });
      if (index > 1) {
        timeline.to(this.slideTimelineProgressTargets[index - 2], { width: '0%', duration: 0.3 });
      }
    }
    this.hideLesson(index)
  }

  onUpdate(self, index) {
    const scrollPercent = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
    this.dispatch('scroll', { progress: scrollPercent * 100 })
    const progress = self.start < 0 ? self.scroll() / self.end : self.progress;
    if (progress >= 0.5 && self.direction > 0) {
      this.showLesson(index);
    }
    if (progress < 0.5) {
      this.hideLesson(index);
    }
  }

  onKeyUp(event) {
    event.preventDefault();
    if (event.key === 'ArrowLeft') {
      this.previousSlide();
    }
    else if (event.key === 'ArrowRight') {
      this.nextSlide();
    }
  }

  showLesson(index) {
    if (index > 0 && this.slideContentTargets[index].dataset.hasLesson == 'true') {
      gsap.to(this.lessonTargets[index - 1], { x: 0, autoAlpha: 1, display: 'block', duration: 0.5 });
      gsap.to(this.backdropTarget, { autoAlpha: 0.7, visibility: 'visible', duration: 0.5 });
    }
  }

  hideLesson(index) {
    if (this.slideContentTargets[index].dataset.hasLesson == 'true') {
      gsap.to(this.lessonTargets[index - 1], { x: '10rem', autoAlpha: 0, display: 'none' });
      gsap.to(this.backdropTarget, { autoAlpha: 0, visibility: 'hidden', duration: 0.5 });
    }
  }

  nextSlide() {
    window.scrollTo({
      top: this.currentScroll + this.scrollStep,
      behavior: 'smooth'
    })
  }

  previousSlide() {
    window.scrollTo({
      top: this.currentScroll - this.scrollStep,
      behavior: 'smooth'
    })
  }

  currentSlideIndexValueChanged() {
    if (this.currentSlideIndexValue === 0) {
      this.backBtnTarget.classList.add('opacity-30');
      this.backBtnTarget.classList.remove('hover:-translate-x-1', 'active:translate-x-0');
    } else if (this.currentSlideIndexValue === this.totalSlides - 1) {
      this.nextBtnTarget.classList.add('opacity-30');
      this.nextBtnTarget.classList.remove('hover:translate-x-1', 'active:translate-x-0');
    }
    else {
      this.backBtnTarget.classList.remove('opacity-30');
      this.nextBtnTarget.classList.remove('opacity-30');
      this.backBtnTarget.classList.add('hover:-translate-x-1', 'active:translate-x-0');
      this.nextBtnTarget.classList.add('hover:translate-x-1', 'active:translate-x-0');
    }
  }

  get currentScroll() {
    return this.slideScrollTriggers[this.currentSlideIndexValue].scroll();
  }

  get scrollStep() {
    return (this.slideContentTargets[this.currentSlideIndexValue].dataset.hasLesson == 'true' ? window.innerHeight / 2 : window.innerHeight) - 5;
  }
}
