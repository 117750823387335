import { Dropdown } from "tailwindcss-stimulus-components"
import { useDispatch } from "stimulus-use";

// Connects to data-controller="custom-select"
export default class extends Dropdown {
  static targets = ['selectedValue', 'option'];
  static values = {
    name: String,
    selected: String,
  };

  connect() {
    super.connect();
    useDispatch(this);
    this.selectedValueTarget.innerHTML = this.optionTargets[0].innerHTML;
  }

  preview(event) {
    this.selectedValueTarget.innerHTML = event.currentTarget.innerHTML;
  }

  select(event) {
    this.selectedValue = event.target.dataset.value;
    this.dispatch('change', { [this.nameValue]: event.target.dataset.value });
  }

  clear() {
    this.selectedValue = '';
  }

  selectedValueChanged() {
    if (this.selectedValue) {
      this.selectedValueTarget.classList.replace('text-gray-500', 'text-flamingo-800');
      this.selectedValueTarget.classList.add('bg-flamingo-100', 'rounded-md', 'text-flamingo-800', 'px-2');
      this.openValue = false;
    } else {
      this.selectedValueTarget.classList.replace('text-flamingo-800', 'text-gray-500');
      this.selectedValueTarget.classList.remove('bg-flamingo-100', 'rounded-md', 'text-flamingo-800', 'px-2');
      this.openValue = true;
      this.selectedValueTarget.innerHTML = this.optionTargets[0].innerHTML;
    }
  }
}
