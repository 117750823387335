// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers/index"
import "../../components/index"
require("jquery")
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import posthog from 'posthog-js'
import mixpanel from 'mixpanel-browser';

function getCookie(key) {
  // Get name followed by anything except a semicolon
  var cookiestring = RegExp(key + "=[^;]+").exec(document.cookie);
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
}

document.addEventListener('turbo:load', () => {
  if (['buildd.co'].includes(window.location.host)) {
    mixpanel.init('fe9e7d66ce886af373db34f01fa98a7b', {
      autotrack: true, loaded: function (mixpanel) {
        const builddUserId = getCookie('buildd_user_id');
        if (builddUserId) {
          mixpanel.identify(builddUserId);
        }
      }
    });
    window.mixpanel = mixpanel;
    // tracking all pageviews with Mixpanel
    mixpanel.track("Page View", {
      "page_title": document.title,
      "page_url": window.location.href
    });
    posthog.init('phc_Hj7mvZiTCqU9dl7kgGElP1VosTVheoPn8H1KvyAVnDB', {
      api_host: 'https://app.posthog.com',
      autocapture: {
        dom_event_allowlist: ['click'],
        event_allowlist: ['click'], // DOM events from this list ['click', 'change', 'submit']
        // element_allowlist: ['button', 'a'], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
        css_selector_allowlist: ['[buildd-ph-track]'],
      },
      capture_pageview: false,
      capture_pageleave: false,
      loaded: function (posthog) {
        const builddUserId = getCookie('buildd_user_id');
        if (builddUserId) {
          posthog.identify(builddUserId);
        }
      }
    });
  }
});


gsap.registerPlugin(ScrollTrigger);

Rails.start()
ActiveStorage.start()
window.Rails = Rails;

// export function showLesson(event) {
//   let lessonsTimeline = [];
//   const currentDate = new Date();

//   if (localStorage.getItem("lessons") !== null) {

//     lessonsTimeline = JSON.parse(localStorage.getItem("lessons"));
//     const lastLessonDate = new Date(lessonsTimeline.slice(-1)[0]);
//     const differenceInDays = (Math.abs(currentDate - lastLessonDate) / (1000 * 60 * 60 * 24));

//     // we only want to show lessons once in a day, and max 10 in a lifetime
//     if (differenceInDays < 1.0 || lessonsTimeline.length > 10) {
//       return;
//     }
//   }

//   const loader = document.querySelector('#loader');
//   if (loader) {

//     lessonsTimeline.push(currentDate);
//     localStorage.setItem("lessons", JSON.stringify(lessonsTimeline));

//     const csrfToken = document.querySelector("[name='csrf-token']").content;
//     const headers = new Headers();
//     headers.append('X-CSRF-Token', csrfToken);
//     headers.append('Accept', 'application/json');

//     fetch('/update_lessons_count', {
//       method: 'POST',
//       headers: headers
//     })
//       .then(res => res.json())

//     event.preventDefault();
//     loader.classList.remove('hidden');
//     setTimeout(() => event.detail.resume(), 2000);
//   }

// };
// show loading screen for every navigation to home page
// document.addEventListener('turbo:before-fetch-request', async (event) => {
//   if (event.detail.url.pathname === '/' && event.target.tagName !== 'TURBO-FRAME' && event.detail.fetchOptions.method === 'GET') {
//     showLesson(event);
//   }
// });

document.addEventListener('turbo:load', async (event) => {
  const loader = document.querySelector('#loader');
  if (loader) {
    loader.classList.add('hidden');
  }
  const scrollProgressBar = document.querySelector("#scroll-progress");
  if (scrollProgressBar) {
    window.onscroll = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      scrollProgressBar.style.width = `${scrolled}%`;
    }
  }

  const progressBar = document.querySelector('.progress');
  if (progressBar) {
    progressBar.style.width = progressBar.getAttribute('data-percent') + "%";
  }
});