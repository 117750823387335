import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// Connects to data-controller="hero-cards-slider"
export default class extends Controller {

  initialize() {
    if (this.isHorizontal) {
      this.startHorizontalAnimation();
    }
    else {
      this.startVerticalAnimation();
    }
    // const headerTween = gsap.to('.header', {
    //   background: 'white',
    //   boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    // });
    // ScrollTrigger.create({
    //   trigger: '.course-description-page',
    //   animation: headerTween,
    //   scrub: true,
    //   start: '50 top',
    //   end: '50 top',
    // });
  }

  startVerticalAnimation() {
    const cards = gsap.utils.toArray(this.element.children);
    const totalHeight = this.element.offsetHeight;

    cards.forEach((card) => {
      const cardHeight = card.offsetHeight;
      const cardOffset = card.offsetTop;
      const cardDistance = cardHeight + cardOffset;
      const tl = gsap.timeline({ repeat: -1, defaults: { ease: "none", duration: totalHeight / 40 } });
      tl.to(card, {
        y: `-=${totalHeight}`,
        modifiers: {
          y: (y, target) => {
            y = parseFloat(y);
            if (y < -cardDistance) {
              y += totalHeight;
            }
            return y + "px";
          }
        }
      }, 0);
    });
  }

  startHorizontalAnimation() {
    const cards = gsap.utils.toArray(this.element.children);
    const totalWidth = this.element.offsetWidth;

    cards.forEach((card) => {
      const cardWidth = card.offsetWidth;
      const cardOffset = card.offsetLeft;
      const cardDistance = cardWidth + cardOffset;
      // const duration = 
      const tl = gsap.timeline({ repeat: -1, defaults: { ease: "none", duration: totalWidth / 25 } });
      tl.to(card, {
        x: `-=${totalWidth}`,
        modifiers: {
          x: (x, target) => {
            x = parseFloat(x);
            if (x < -cardDistance) {
              x += totalWidth;
            }
            return x + "px";
          }
        }
      }, 0);
    });
  }

  get isHorizontal() {
    const value = this.data.get('isHorizontal') || 'false';
    return value == 'true';
  }
}
