import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hamburger-menu"
export default class extends Controller {
  static targets = ['icon', 'menu'];

  toggle() {
    this.iconTarget.children[0].classList.toggle('-translate-y-1.5');
    this.iconTarget.children[0].classList.toggle('rotate-45');
    this.iconTarget.children[1].classList.toggle('opacity-0');
    this.iconTarget.children[2].classList.toggle('translate-y-1.5');
    this.iconTarget.children[2].classList.toggle('-rotate-45');
    this.menuTarget.classList.toggle('hidden');
    this.menuTarget.style.height = `calc(100vh - ${this.menuTarget.getBoundingClientRect().top}px)`;
  }
}
