import { Controller as BaseController } from "@hotwired/stimulus";
import { useDispatch } from "stimulus-use";

export class Controller extends BaseController {
  static targets = ["track", "indicator", "navigator", "back", "next"];

  static values = {
    count: Number
  }

  initialize() {
    this.index = 0;
    this.showCurrentSlide();
  }

  connect() {
    useDispatch(this);
  }

  back() {
    if (this.index > 0) {
      this.index--;
      this.showCurrentSlide();
      this.dispatch('change');
    }
  }

  next() {
    if (this.index < this.countValue - 1) {
      this.index++;
      this.showCurrentSlide();
      this.dispatch('change');
    }

  }

  move({ params: { id } }) {
    this.index = id;
    this.showCurrentSlide();
    this.dispatch('change');
  }

  showCurrentSlide() {
    this.trackTarget.style = `transform: translateX(${this.index * -100}%);`;
    this.indicatorTarget.innerText = `${this.index + 1}/${this.countValue}`;
    this.navigatorTargets.forEach((element, index) => {
      if (index === this.index) {
        element.classList.add('bg-primary');
        element.classList.remove('bg-gray-400');
      }
      else {
        element.classList.remove('bg-primary');
        element.classList.add('bg-gray-400');
      }
    });
    if (this.index === 0) {
      this.backTarget.classList.add("disabled");
    } else {
      this.backTarget.classList.remove("disabled");
    }
    if (this.index === this.countValue - 1) {
      this.nextTarget.classList.add("disabled");
    } else {
      this.nextTarget.classList.remove("disabled");
    }
  }
}
