import { Controller } from "@hotwired/stimulus"

export default class SliderController extends Controller {
  static targets = ["track", "indicator", "back", "next"];

  static values = {
    count: Number,
    slide: Number
  }

  initialize() {
    this.index = 0;
  }

  back() {
    if (this.index > 0) {
      this.index--;
      this.showCurrentSlide();
    }
  }

  next() {
    if (this.index < this.countValue - 1) {
      this.index++;
      this.showCurrentSlide();
    }
  }

  showCurrentSlide() {
    this.trackTarget.style = `transform: translateX(${this.index * -(this.slideValue)}%);`;
  }
}