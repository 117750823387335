// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component_name in the component class.
//
import ScrollTrigger from "../../javascript/controllers/scroll_trigger_controller";
import { useDispatch } from "stimulus-use";

export class Controller extends ScrollTrigger {
  static targets = ['progress', 'countdown'];
  static values = {
    wait: Number
  }

  action() {
    this.element.classList.add('-translate-y-[calc(100%+49px)]');
    this.element.classList.replace('md:translate-y-[calc(100%+1rem)]', 'md:translate-y-0');
    this.interval = setInterval(this.updateTimer.bind(this), 1000);
  }

  connect() {
    super.connect();
    useDispatch(this);
    this.stopped = false;
  }

  updateTimer() {
    if (this.waitValue > 0) {
      this.waitValue--;
    }
    if (this.waitValue === 0) {
      clearInterval(this.interval)
      this.dispatch("end");
    }
  }

  waitValueChanged() {
    this.countdownTarget.innerText = this.waitValue;
  }

  pause() {
    if (!this.stopped) {
      clearInterval(this.interval);
    }
  }

  resume() {
    if (!this.stopped) {
      this.interval = setInterval(this.updateTimer.bind(this), 1000);
    }
  }

  hide(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.element.classList.remove('-translate-y-[calc(100%+49px)]');
    this.element.classList.replace('md:translate-y-0', 'md:translate-y-[calc(100%+1rem)]');
    clearInterval(this.interval);
    this.stopped = true;
  }

  end() {
    this.hide();
    this.dispatch("end");
  }
}
