import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-clamp"
export default class extends Controller {
  static targets = ['text', 'buttonText', 'arrow'];

  static values = {
    isActive: Boolean
  }

  toggle(e) {
    e.preventDefault();
    this.textTarget.classList.toggle('paragraph-clamp');
    this.arrowTarget.classList.toggle('rotate-180');
    this.isActiveValue = !this.isActiveValue;
  }

  isActiveValueChanged() {
    this.buttonTextTarget.innerText = this.isActiveValue ? 'Show less' : 'Show more';
  }
}
