import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Connects to data-controller="overview"
export default class extends Controller {

  static targets = ['index', 'mobileIndex', "mobileProgressBar", "scrollUpButton"];
  static values = {
    singleProgressBar: Boolean
  }

  connect() {
    this.removeElementIfNotNeeded();
    const scrollDownBtn = document.querySelector('#scroll-down');
    if (scrollDownBtn) {
      scrollDownBtn.addEventListener('click', () => {
        const articleSection = document.querySelector('.article');
        const offsetTop = articleSection.getBoundingClientRect().top + document.documentElement.scrollTop;
        window.scrollTo({
          top: offsetTop - 64,
          behavior: "smooth"
        });
      });
    }

    let header = document.querySelector('.header');
    // Uncomment to enable "Overview" in mobile view.
    // if (window.innerWidth < 769) {
    //   header = document.querySelector('.header-mobile');
    // }
    // else {
    //   header = document.querySelector('.header');
    // }

    // header transition
    const headerTwin = gsap.to(header, {
      opacity: 1,
      display: 'block'
    });
    ScrollTrigger.create({
      animation: headerTwin,
      trigger: '.hero-banner',
      scrub: true,
      start: 'bottom 50%',
      end: 'bottom 64',
    });

    // progress bar transition
    this.topics = document.querySelectorAll('.topic');
    this.triggers = Array.from(this.topics).map(topic =>
      ScrollTrigger.create({
        trigger: topic,
        scrub: true,
        end: () => `bottom ${window.innerHeight / 4}`,
        start: () => `top ${window.innerHeight / 4}`,
        onEnter: this.startProgress.bind(this),
        onEnterBack: this.startProgress.bind(this),
        onLeave: this.endProgress.bind(this),
        onLeaveBack: this.endProgress.bind(this),
        onUpdate: this.updateProgress.bind(this)
      })
    );
  }

  startProgress(self) {
    if (this.hasMobileIndexTarget) {
      this.mobileIndexTarget.innerText = self.trigger.firstElementChild.innerText;
    }
    else if (this.hasIndexTarget) {
      const indexTarget = this.indexTargets.find(index =>
        index.getAttribute('data-overview-topic-param') === self.trigger.getAttribute('id')
      );
      if (indexTarget) {
        indexTarget.classList.add('active');
      }
    }
  }

  updateProgress(self) {
    if (this.hasMobileProgressBarTarget) {
      this.mobileProgressBarTarget.style.width = `${self.progress * 100}%`;
    }
    else {
      this.indexTargets.forEach(index => {
        if (index.getAttribute('data-overview-topic-param') === self.trigger.getAttribute('id') && self.isActive) {
          index.style.width = `${self.progress * 100}%`;
        }
      });
    }
  }

  endProgress(self) {
    if (!this.hasMobileIndexTarget) {
      const indexTarget = this.indexTargets.find(index =>
        index.getAttribute('data-overview-topic-param') === self.trigger.getAttribute('id')
      );
      if (indexTarget) {
        indexTarget.style.width = '0px';
        indexTarget.classList.remove('active');
      }
    }
  }

  disconnect() {
    this.triggers.forEach(trigger => trigger.kill());
  }

  removeElementIfNotNeeded() {
    // remove mobile element for desktop view
    if (this.hasMobileProgressBarTarget && window.innerWidth > 768) {
      this.element.remove();
    }
    // remove desktop element for mobile view
    else if (!this.hasMobileProgressBarTarget && window.innerWidth < 768) {
      this.element.remove();
    }
  }

  goto({ params: { topic } }) {
    const topicSection = document.querySelector(`#${topic}`)
    const offsetTop = topicSection.getBoundingClientRect().top + document.documentElement.scrollTop;
    window.scrollTo({
      top: (offsetTop - window.innerHeight / 4) + 2,
      behavior: "smooth"
    });
  }
}