import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select/dist/slimselect.es';
import { useDispatch } from "stimulus-use";
// import 'slim-select/dist/slimselect.css';

// Connects to data-controller="slim-select"
export default class extends Controller {
  initialize() {
    this.bindedOnData = this.onData.bind(this);
  }

  connect() {
    useDispatch(this);
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        allowDeselect: true,
        placeholderText: `Select ${this.name || 'value'}`,
      },
      events: {
        afterChange: this.onChange.bind(this)
      }
    })

    window.addEventListener(`location:${this.name}:data`, this.bindedOnData);
  }

  disconnect() {
    this.select.destroy()
    window.removeEventListener(`location:${this.name}:data`, this.bindedOnData);
  }

  onChange(selectedOption) {
    this.dispatch(this.name + ':select', { value: selectedOption[0] ? selectedOption[0].value : '' });
  }

  onData(event) {
    this.select.setData(event.detail.data);
  }

  get name() {
    return this.data.get('name') || ''
  }
}
