import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    triggerSection: String
  }

  initialize() {
    this.boundedTrigger = this.trigger.bind(this);
    this.triggered = false;
  }

  connect() {
    window.addEventListener('scroll', this.boundedTrigger);
    // Initially hide the bottom bar
    this.hideBottomBar();
  }

  trigger() {
    const scrollPercent = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);

    if (scrollPercent > 0 && this.isSectionInView(this.triggerSectionValue)) {
      this.showBottomBar();
      this.triggered = true;
    } else if (this.triggered && !this.isSectionInView(this.triggerSectionValue)) {
      this.hideBottomBar();
      this.triggered = false;
    }
  }

  isSectionInView(sectionId) {
    const section = document.getElementById(sectionId);
    if (!section) return false;
    const sectionRect = section.getBoundingClientRect();
    return sectionRect.top <= window.innerHeight;
  }

  showBottomBar() {
    const bottomBar = this.element;
    bottomBar.classList.remove('hidden');

  }

  hideBottomBar() {
    const bottomBar = this.element;
    bottomBar.classList.add('hidden');
  }
}
