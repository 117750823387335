import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

// Connects to data-controller="confetti"
export default class extends Controller {
  connect() {
    if (this.showOnLoad) {
      this.animateConfetti();
    }
  }

  animateConfetti() {
    gsap.set("img", { xPercent: "-50%", yPercent: "-50%" })

    const total = 50;
    const w = screen.width;
    const h = screen.height + 100;

    for (let i = 0; i < total; i++) {
      const confetti = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      confetti.setAttributeNS(null, "class", "dot");
      confetti.setAttributeNS(null, "width", 15);
      confetti.setAttributeNS(null, "height", 20);
      this.element.appendChild(confetti);
      gsap.set(confetti, { x: maxRandom(w), y: 0, rotation: maxRandom(180), opacity: 1, scale: maxRandom(0.5) + 0.5, fill: "hsl(" + randomInRange(0, 150) + ",50%,50%)" });
      animate(confetti);
    }

    function animate(element) {
      gsap.to(element, { duration: maxRandom(5) + 4, y: h, ease: 'linear', repeat: 1, delay: -5 });
      gsap.to(element, { duration: maxRandom(5) + 1, x: '+=70', repeat: 3, yoyo: true, ease: 'sine.inOut' })
      gsap.to(element, { duration: maxRandom(5) + 1, scaleX: 0.2, rotation: maxRandom(360), repeat: 3, yoyo: true, ease: 'sine.inOut' })
      gsap.to(element, { duration: maxRandom(1) + 0.5, opacity: 0, repeat: 3, yoyo: true, ease: 'sine.inOut' })
    };

    function maxRandom(max) {
      return Math.random() * max;
    }

    function randomInRange(min, max) {
      return min + Math.floor(Math.random() * (max - min));
    }
  }

  get showOnLoad() {
    return this.data.get('showOnLoad');
  }
}
