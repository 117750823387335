import { Controller } from "@hotwired/stimulus"
import fetchJsonp from 'fetch-jsonp';

// Connects to data-controller="gists"
export default class extends Controller {

  connect() {
    fetchJsonp(this.url)
      .then(res => res.json())
      .then(res => {
        this.element.insertAdjacentHTML('beforeend', `<link href="${res.stylesheet}" rel="stylesheet">`);
        this.element.insertAdjacentHTML('beforeend', res.div);
      })
      .catch(console.log);
  }

  get url() {
    return this.data.get('url') || '';
  }
}
