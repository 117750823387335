import { Controller } from "@hotwired/stimulus"
import mixpanel from 'mixpanel-browser';

// Connects to data-controller="mixpanel"
export default class extends Controller {
  static targets = ['track'];

  trackButtonClick(event) {
    const { eventname, properties } = event.currentTarget.dataset;

    if (!eventname) return;

    const formattedEventName = `WEB_BUTTON_CLICK_${eventname.toUpperCase().replace(/[\s\t]+(?=\S)/g, '_')}`;

    if (properties) {
      mixpanel.track(formattedEventName, JSON.parse(properties));
    } else {
      mixpanel.track(formattedEventName);
    }
  }
}
