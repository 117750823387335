import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video"]

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this));
    this.videos.forEach(video => {
      // Chrome does not allow autoplay with unmute
      video.muted = true;
      video.autoplay = true;
      this.observer.observe(video);
    });
  }

  handleIntersection(entries) {
    const intersectingVideo = entries.find(entry => entry.isIntersecting);
    if (intersectingVideo) {
      intersectingVideo.target.play();
      this.videos.forEach(video => {
        if (video !== intersectingVideo.target) {
          video.pause();
        }
      });
    }
  }


  get videos() {
    return this.videoTargets
  }
}