import { Toggle } from "tailwindcss-stimulus-components"

// Connects to data-controller="custom-toggle"
export default class extends Toggle {
  static targets = ['arrow', 'showMoreText'];

  showWithoutPrevent() {
    this.openValue = true;
  }

  openValueChanged() {
    super.openValueChanged();
    if (this.hasArrowTarget) {
      this.arrowTarget.classList.toggle('rotate-180');
    }
    if (this.hasShowMoreTextTarget) {
      if (this.openValue) {
        this.showMoreTextTarget.innerText = 'Show less';
      }
      else {
        this.showMoreTextTarget.innerText = 'Show more';
      }
    }
  }
}
