import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bottom-bar"
export default class extends Controller {
  toggleTagsSection() {
    this.element.classList.toggle('translate-y-[calc(100%-49px)]');
    this.element.classList.toggle('translate-y-[calc(100%-98px)]');
  }

  showComments() {
    const commentsSection = document.querySelector('#comments');
    const offsetTop = commentsSection.getBoundingClientRect().top + document.documentElement.scrollTop;
    window.scrollTo({
      top: offsetTop - 100,
      behavior: "smooth"
    });
  }
}
