import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-message"
export default class extends Controller {

  connect() {
    const self = this;
    setTimeout(function () {
      self.fadeOut(self.element);
    }, 10000);
  }

  close() {
    this.fadeOut(this.element);
  }

  fadeOut(el) {
    el.style.opacity = 1;
    (function fade() {
      if ((el.style.opacity -= .1) < 0) {
        el.style.display = "none";
      } else {
        requestAnimationFrame(fade);
      }
    })();
  };
}
