import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to-top"
export default class extends Controller {
  connect() {
    this.lastscrollY = 0;
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(event) {
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;
    if (this.lastscrollY > scrollY && this.getScrollPercent() > 50) {
      this.element.classList.add("md:flex");
    }
    else {
      this.element.classList.remove("md:flex");
    }
    this.lastscrollY = scrollY <= 0 ? 0 : scrollY;;
  }

  getScrollPercent() {
    let elementHeight = document.documentElement,
      body = document.body,
      scrollTop = 'scrollTop',
      scrollHeight = 'scrollHeight';
    return (elementHeight[scrollTop] || body[scrollTop]) / ((elementHeight[scrollHeight] || body[scrollHeight]) - elementHeight.clientHeight) * 100;
  }

  scrollToTop() {
    this.element.classList.add("hidden")
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
}
