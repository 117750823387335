import { Controller } from "@hotwired/stimulus"
import { fetchEventSource } from '@microsoft/fetch-event-source';
// import mixpanel from 'mixpanel-browser';

// To create HTML structure for searchGPT result resource
function getHTMLContentForSearchableResource(resource) {
  return `
  <div class="group flex flex-col sm:flex-row gap-4 relative ml-2 p-3 w-full bg-background border border-slate-300 hover:border-slate-400 rounded-lg">
    <div class="flex flex-col items-start">
      <div class="flex w-full mt-1 mb-2">
        <div class="flex-none w-6">
          <img alt=${resource.curator.full_name} class="rounded-full w-5 h-5" loading="" src=${resource.curator.avatar_url}>
        </div>
        <div class="grow flex flex-wrap items-center">
          <div class="text-gray-600 text-sm mr-1"> Curated by </div>
          <div class="text-gray-700 text-sm">
            <strong>${resource.curator.full_name}</strong>
          </div>
        </div>
      </div>
      <h4 class="text-lg p-0 font-bold">${resource.title}</h4>
      <p class="text-textBlack text-base font-bold">
        ${resource.description}
      </p>
    </div>
  </div>
  `;
}

function getContentFromData(msg) {
  let data;
  if (typeof msg.data === "string") {
    try {
      data = JSON.parse(msg.data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  } else {
    data = msg.data;
  }

  if (data && data.hasOwnProperty("content")) {
    return data.content;
  }

  return null
}

function getArrowElement() {
  const arrow = document.createElement('i');
  arrow.classList.add('ri-arrow-right-line', 'text-xl', 'ml-1', 'group-hover:translate-x-1', 'transition-transform');

  return arrow;
}

function createSignInButton() {
  const a = document.createElement('a');
  a.href = decodeURI('/login');
  a.classList.add('button', 'group', 'button-primary', 'mt-6', 'flex', 'items-center', 'align-center');
  a.innerText = 'Sign in to get insights from your network';
  a.appendChild(getArrowElement());
  return a;
}

function createPostButton(query) {
  const div = document.createElement('div');

  const p = document.createElement('p');
  p.innerText = "This will post a question on your feed, so that your connections on buildd can respond with their insights on the topic.";
  p.classList.add('text-sm', 'text-slate-500', 'mt-1');

  const button = document.createElement('a');
  button.innerText = 'Want insights from your network? Ask now';
  button.classList.add('button', 'group', 'button-primary', 'mt-6', 'flex', 'items-center', 'align-center');

  const arrow = getArrowElement();
  button.appendChild(arrow);

  button.addEventListener('click', () => {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    // Disable the button
    button.disabled = true;

    // Create the spinner element
    const spinner = document.createElement('div');
    spinner.innerHTML = `
      <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
      </div>
    `;

    button.innerText = ''

    button.appendChild(spinner);

    fetch('/api/v1/posts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ description: query }),
    })
      .then(response => {
        // Redirect to the "/home" path after the API request completes
        window.location.href = '/home';
      })
      .catch(error => {
        // Re-enable the button and remove the spinner element
        button.disabled = false;
        button.removeChild(spinner);
        button.innerText = 'Want insights from your network? Ask now';
      });
  });

  div.appendChild(button);
  div.appendChild(p);

  return div;
}

// Connects to data-controller="stream"
export default class extends Controller {
  static targets = ['form', 'button', 'result', 'loader', 'responseContainer', 'resourcesSection', 'resourcesNote', 'networkResourcesNote', 'resourcesList', 'networkResponseContainer', 'networkResourcesSection', 'networkResult', 'networkResourcesList', 'networkLoader', 'blinker', 'networkConnectionsSection'];
  static values = {
    product: String,
    builddUserId: Number || null
  }

  connect() {
    this.csrfToken = document.querySelector("[name='csrf-token']").content;
    this.showFormattedHighlights = !(window.location.pathname.includes('v1') || window.location.pathname.includes('v2'));
    this.showAskQuestionButton = !(window.location.pathname.includes('v2') || window.location.pathname.includes('v3'));
    this.content = "";
    this.networkContent = "";

    this.formTarget.addEventListener('submit', (event) => {
      event.preventDefault();

      this.disableButton();

      this.responseContainerTarget.classList.remove('hidden');
      if (this.hasNetworkResponseContainerTarget) {
        this.networkResponseContainerTarget.classList.remove('hidden');
      }

      this.resultTarget.classList.add('hidden');
      if (this.hasNetworkResultTarget) {
        this.networkResultTarget.classList.add('hidden');
      }

      this.loaderTarget.classList.remove('hidden');
      if (this.hasNetworkLoaderTarget) {
        this.networkLoaderTarget.classList.remove('hidden');
      }

      this.resourcesSectionTarget.classList.add('hidden');
      if (this.hasNetworkResourcesSectionTarget) {
        this.networkResourcesSectionTarget.classList.add('hidden');
      }

      if (this.hasNetworkConnectionsSectionTarget) {
        this.networkConnectionsSectionTarget.classList.add('hidden');
      }

      this.resourcesListTarget.innerHTML = '';
      if (this.hasNetworkResourcesListTarget) {
        this.networkResourcesListTarget.innerHTML = '';
      }

      const formData = new FormData(event.target);

      if (!formData.get('query')) {
        this.displayStatusMessage({ event: 'error', data: 'Please enter a valid query.' });
        this.enableButton();
        return;
      }

      const current_url = window.location.href
      const is_search_gpt = current_url.includes('/search/');
      const version = is_search_gpt ? current_url.substring(current_url.lastIndexOf('/') + 1) : null

      fetchEventSource('/api/v1/search', {
        openWhenHidden: true, // keep the connection when user switches tab
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        },
        body: JSON.stringify({
          query: formData.get('query'),
          product: this.productValue,
          buildd_user_id: this.builddUserIdValue,
          prompt: formData.get('prompt'),
          prompt_1: formData.get('prompt_1'),
          prompt_2: formData.get('prompt_2'),
          version: version,
        }),

        onopen: (async (response) => {
          try {
            if (['buildd.co'].includes(window.location.host)) {
              mixpanel.track("WEB_BUTTON_CLICK_SEARCH_OR_SEO_GPT_QUERY", {
                query: formData.get('query'),
                prompt: formData.get('prompt'),
              });
            }
          } catch (error) {
            console.log(error)
          }

          this.content = this.resultTarget.innerText = '';

          this.resultTarget.classList.add('hidden');
          if (this.hasNetworkResultTarget) {
            this.networkContent = this.networkResultTarget.innerText = '';
            this.networkResultTarget.classList.add('hidden');
          }

          if (this.hasBlinkerTarget) {
            this.blinkerTarget.children[0].innerText = "Fetching...";
            this.blinkerTarget.classList.add('hidden');
          }

          if (this.hasNetworkConnectionsSectionTarget) {
            this.networkConnectionsSectionTarget.classList.add('hidden');
          }

          this.loaderTarget.classList.remove('hidden');
          if (this.hasNetworkLoaderTarget) {
            this.networkLoaderTarget.classList.remove('hidden');
          }

          this.resourcesSectionTarget.classList.add('hidden');
          if (this.hasNetworkResourcesSectionTarget) {
            this.networkResourcesSectionTarget.classList.add('hidden');
          }

          this.resourcesListTarget.innerHTML = '';
          if (this.hasNetworkResourcesListTarget) {
            this.networkResourcesListTarget.innerHTML = '';
          }

          const EventStreamContentType = 'text/event-stream';
          if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
            return; // everything's good
          } else if (response.status === 204) {
            this.displayStatusMessage({ event: 'error', data: { 'content': 'Please enter a valid query.' } });
          } else if (response.status === 429) {
            this.displayStatusMessage({ event: 'error', data: { 'content': 'You have reached the limit. Please try again later!' } });
          } else {
            this.displayStatusMessage({ event: 'error', data: { 'content': 'Oops! Something went wrong. Please try again.' } });
          }
        }).bind(this),

        onmessage: ((msg) => {
          // if the server emits an error message, throw an exception
          // so it gets handled by the onerror callback below:

          if (msg.event === 'error') {
            this.displayStatusMessage(msg);
          }
          else if (msg.event == 'resources') {
            this.resourcesSectionTarget.classList.remove('hidden');
            try {
              const resources = JSON.parse(msg.data).resources;
              resources.forEach(((resource) => {
                const li = document.createElement('li');
                const a = document.createElement('a');
                a.href = decodeURI(resource.url);
                if (resource.resource_class == 'SearchableContent') {
                  if (this.showFormattedHighlights) {
                    a.innerHTML = getHTMLContentForSearchableResource(resource);
                  } else {
                    a.innerText = resource.title;
                    a.classList.add('text-slate-500', 'underline')
                    this.resourcesListTarget.classList.add('list-disc', 'list-inside')
                  }
                } else {
                  a.innerHTML = `
                    <div class="group flex flex-col sm:flex-row gap-4 relative ml-2 p-3 w-full bg-background border border-slate-300 hover:border-slate-400 rounded-lg">
                      <div class="rounded-xl relative cursor-pointer h-fit shrink-0">
                        <img src="${resource.image_url}" class="rounded-lg h-40 w-32 sm:h-24 object-cover">
                        <span class="absolute top-1/2 left-1/2 w-8 h-8 flex justify-center items-center bg-white rounded-full -translate-x-1/2 -translate-y-1/2 shadow-xl group-hover:scale-110 transition-transform">
                          <i class="ri-play-fill text-gray-400"></i>
                        </span>
                      </div>
                      <div class="flex flex-col items-start font-bold">
                        <h4 class="text-lg p-0">${resource.title}</h4>
                        <p class="text-textBlack text-base">
                          ${resource.description}
                        </p>
                      </div>
                    </div>
                  `;
                }
                a.target = '_blank';
                li.appendChild(a);
                this.resourcesListTarget.appendChild(li);
              }).bind(this));
            } catch (error) {
              if (this.hasResourcesNoteTarget) {
                this.resourcesNoteTarget.innerText = getContentFromData(msg);
              }
            }
          } else if (msg.event == 'network_resources') {
            this.networkResourcesSectionTarget.classList.remove('hidden');
            try {
              const network_resources = JSON.parse(msg.data).resources;
              network_resources.forEach(((resource) => {
                const li = document.createElement('li');
                const a = document.createElement('a');
                a.href = decodeURI(resource.url);
                if (this.showFormattedHighlights) {
                  a.innerHTML = getHTMLContentForSearchableResource(resource);
                } else {
                  a.innerText = resource.title;
                  a.classList.add('text-slate-500', 'underline')
                  this.networkResourcesListTarget.classList.add('list-disc', 'list-inside')
                }
                a.target = '_blank';
                li.appendChild(a);
                this.networkResourcesListTarget.appendChild(li);
              }).bind(this));
            } catch (error) {
              if (this.hasNetworkResourcesNoteTarget) {
                this.networkResourcesNoteTarget.innerText = getContentFromData(msg);
                this.networkResultTarget.appendChild(createPostButton(formData.get('query')));
              }
            }
            if (this.hasBlinkerTarget) {
              this.blinkerTarget.classList.remove('hidden');
            }
          } else if (msg.event == 'network_result') {
            // network results exist
            this.showNetworkResult(msg);
            this.networkResultTarget.innerText = this.networkContent;
          } else if (msg.event == 'login_for_network_result') {
            // network results do not exist because user is not logged in
            this.showNetworkResult(msg);
            this.networkResultTarget.innerText = "Get answers from content handpicked by your network.";
            this.networkResultTarget.appendChild(createSignInButton());
          } else if (msg.event == 'no_network_result') {
            // network results do not exist because user's connections haven't curated any resource related to query
            // if (version == 'v4') {
            this.showNetworkResult(msg);

            const isOnlyNoNetwork = getContentFromData(msg) != ''
            const row = document.createElement('hr');
            row.classList.add(isOnlyNoNetwork ? 'mt-2' : 'mt-8', 'mb-4');
            const messageContainer = document.createElement('div');
            //messageContainer.innerText = "Want more in-depth answer? Get help from your network!";
            if (this.showAskQuestionButton) {
              messageContainer.appendChild(createPostButton(formData.get('query')));
            }
            if (isOnlyNoNetwork) {
              const p = document.createElement('p');
              p.innerText = getContentFromData(msg);
              p.classList.add('text-slate-600', 'mt-2');
              this.networkResultTarget.appendChild(p);
              if (this.hasNetworkConnectionsSectionTarget) {
                this.networkConnectionsSectionTarget.classList.remove('hidden');
              }
            }
            this.networkResultTarget.appendChild(row);
            this.networkResultTarget.appendChild(messageContainer);
            if (this.hasBlinkerTarget) {
              this.blinkerTarget.classList.remove('hidden');
            }
            // }
            // else {
            //   this.showNetworkResult(msg);
            //   this.networkResultTarget.innerText = "Your network hasn\'t shared any content on this yet!";
            //   if (this.showAskQuestionButton) {
            //     this.networkResultTarget.appendChild(createPostButton(formData.get('query')));
            //   }
            // }
          } else if (msg.event == 'status') {
            this.displayStatusMessage(msg);
          } else {
            const contentData = getContentFromData(msg)
            if (contentData) {
              this.content += contentData == '' ? '\n' : contentData;
              this.loaderTarget.classList.add('hidden')
              this.resultTarget.classList.remove('hidden')
              this.resultTarget.innerText = this.content;
            }
          }
        }).bind(this),

        onclose: (() => {
          this.enableButton();
          if (this.hasBlinkerTarget) {
            this.blinkerTarget.children[0].innerText = "Ready";
          }
        }).bind(this),

        onerror(err) {
          console.log(err);
          this.enableButton();
        }
      });
    });
  }

  displayStatusMessage(message) {
    if (getContentFromData(message)) {
      this.resultTarget.innerHTML = '';
      this.loaderTarget.classList.add('hidden')
      this.resultTarget.classList.remove('hidden')
      const statusMessage = document.createElement('p');
      statusMessage.innerText = getContentFromData(message);
      statusMessage.classList.add('italic', 'test-base', message.event === 'error' ? 'text-rose-500' : 'text-slate-500');
      this.resultTarget.appendChild(statusMessage);
      if (this.hasNetworkLoaderTarget) {
        this.networkLoaderTarget.classList.remove('hidden');
      }
      if (this.hasNetworkResultTarget) {
        this.networkResultTarget.innerHTML = '';
        this.networkResultTarget.classList.remove('hidden')
        this.networkResultTarget.appendChild(statusMessage.cloneNode(true));
      }
    }
  }

  showNetworkResult(msg) {
    this.networkContent += getContentFromData(msg) == '' ? '\n' : getContentFromData(msg);
    this.networkLoaderTarget.classList.add('hidden')
    this.networkResultTarget.classList.remove('hidden')
  }

  disableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true;
    }
  }

  enableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false;
    }
  }
}
