import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { useIntersection } from 'stimulus-use';

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["input", "fileDropArea", "startupTopicCheckbox", "avatarPreview", "strike", "moneySavedButton", "avatarActions", "animatedButton", "endDate"];

  startupTopicSelectionLimit = 4;

  connect() {
    if (this.optionalProfile) {
      if (this.hasAnimatedButtonTarget) {
        useIntersection(this, { element: this.animatedButtonTarget, rootMargin: '0px 0px -25% 0px' });
      }
      else {
        this.playPaymentButtonAnimation(true);
      }
    }

    let fileInput;
    this.inputTargets.forEach(target => {
      if (target.type === 'submit') {
        this.submitTarget = target;
      }
      else if (target.type === 'file') {
        fileInput = target;
      }
    });
    if (fileInput && this.hasFileDropAreaTarget) {
      const activateDropArea = () => {
        this.fileDropAreaTarget.classList.add('is-active');
      };
      const deActivateDropArea = () => {
        this.fileDropAreaTarget.classList.remove('is-active');
      };

      fileInput.addEventListener('dragenter', activateDropArea);
      fileInput.addEventListener('focus', activateDropArea);
      fileInput.addEventListener('click', activateDropArea);

      fileInput.addEventListener('dragleave', deActivateDropArea);
      fileInput.addEventListener('blur', deActivateDropArea);
      fileInput.addEventListener('drop', deActivateDropArea);
    }
    if (this.submitTarget && !this.optionalProfile) {
      this.submitTarget.disabled = true;
    }
  }

  appear() {
    this.playPaymentButtonAnimation(true);
  }

  disconnect() {
    this.paymentButtonAnimationTimeline && this.paymentButtonAnimationTimeline.kill();
  }

  onChange() {
    let isValid = true, originalPassword;
    for (let i = 0; i < this.inputTargets.length; i++) {
      const target = this.inputTargets[i];
      if (target.type === 'email' && !this.isEmailValid(target.value)) {
        isValid = false;
        break;
      }
      else if (target.type === 'password') {
        if (target.required && !this.isPasswordValid(target.value)) {
          isValid = false;
          break;
        }
        else if (!originalPassword) {
          originalPassword = target.value;
        }
        else if (target.required && originalPassword !== target.value) {
          isValid = false;
        }
      }
      else if (target.required && !target.value) {
        isValid = false;
        break;
      }
    }
    if (this.submitTarget) {
      this.submitTarget.disabled = !isValid;
    }
  }

  onProfileChange() {
    let isValid = false;
    for (let i = 0; i < this.inputTargets.length; i++) {
      const target = this.inputTargets[i];
      if (target.type === 'text' && this.isUrlValid(target.value)) {
        isValid = true;
        break;
      }
      else if (target.type === 'file') {
        isValid = target.files.length > 0;
      }
    }
    if (this.submitTarget) {
      this.submitTarget.disabled = !isValid;
    }
    if (!this.optionalProfile) this.playPaymentButtonAnimation(isValid);
  }

  playPaymentButtonAnimation(isValid) {
    if (this.hasStrikeTarget && this.hasMoneySavedButtonTarget) {
      if (!isValid) {
        return this.paymentButtonAnimationTimeline && this.paymentButtonAnimationTimeline.timeScale(3).reverse();
      }
      if (this.paymentButtonAnimationTimeline) {
        return this.paymentButtonAnimationTimeline.timeScale(1).play();
      }
      this.paymentButtonAnimationTimeline = gsap.timeline();
      this.paymentButtonAnimationTimeline.to(this.strikeTarget, {
        scaleX: 1,
        duration: 0.7,
        transformOrigin: 'center left'
      });
      this.paymentButtonAnimationTimeline.to(this.moneySavedButtonTarget, {
        opacity: 1,
        visibility: 'visible',
        duration: 0.75,
      });
    }
  }

  onFileUpload(event) {
    const filePath = event.target.value;

    // Allowing file type
    const allowedExtensions = /(\.pdf)$/i;
    if (!filePath) {
      document.querySelector('.file-upload-content').classList.remove('hidden');
      const fileDetails = document.querySelector('.file-details');
      fileDetails.classList.add('hidden');
      fileDetails.classList.remove('flex');
      return false;
    }
    if (!allowedExtensions.exec(filePath)) {
      alert('Only PDF files are supported.');
      event.target.value = '';
      return false;
    }
    document.querySelector('.file-upload-content').classList.add('hidden');
    const fileDetails = document.querySelector('.file-details');
    fileDetails.classList.remove('hidden');
    fileDetails.classList.add('flex');
    document.querySelector('.file-name').innerText = event.target.value.split('\\').pop();
  }

  isPasswordValid(password) {
    return !!password && password.length >= 8
  }

  isEmailValid(email) {
    return !!email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w*)+$/.test(email);
  }

  isUrlValid(url) {
    if (this.optionalProfile && url.trim() === '') return true;

    try {
      if (!URL || !window.URL) {
        return true;
      }
      const urlData = new window.URL(url);
      return true;
    }
    catch (err) {
      return false;
    }

  }

  togglePasswordVisibility(event) {
    if (event.target.previousElementSibling.type === "password") {
      event.target.previousElementSibling.type = "text";
      event.target.src = event.target.getAttribute('data-hide-icon');
    }
    else {
      event.target.previousElementSibling.type = "password";
      event.target.src = event.target.getAttribute('data-show-icon');
    }
  }

  limitCheckBoxSelection(event) {
    let count = 0;
    this.startupTopicCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        count++;
      }
    });
    if (count > this.startupTopicSelectionLimit) {
      event.target.checked = false;
    }
  }

  onAvatarChange(event) {
    if (this.hasAvatarPreviewTarget) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      const preview = this.avatarPreviewTarget;
      reader.onload = () => {
        preview.src = reader.result;
        preview.classList.remove('hidden');
      };
    }
    if (this.hasAvatarActionsTarget) {
      if (event.target.files.length > 0) {
        this.avatarActionsTarget.classList.remove('hidden');
      } else {
        this.avatarActionsTarget.classList.add('hidden');
      }
    }
  }

  disableEndDate(e) {
    if (e.target.checked) {
      this.endDateTargets.forEach((endDateTarget) => {
        endDateTarget.disabled = true;
      });
    } else {
      this.endDateTargets.forEach((endDateTarget) => {
        endDateTarget.disabled = false;
      });
    }
  }

  get optionalProfile() {
    return this.data.get('optionalProfile') === 'true';
  }
}
