import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";


export default class extends Controller {

  static targets = ['progressBar', 'progressUserIcon'];

  static values = {
    width: Number,
    remOffset: Number
  }

  connect() {
    let self = this;

    // 1.8 rem -> px offset for author icon above progress bar
    this.remOffset = 1.8 * parseFloat(getComputedStyle(document.documentElement).fontSize);

    let widthObj = { w: 0 };
    gsap.to(widthObj, { duration: 0.5, w: this.widthValue, onUpdate: updateWidth });

    function updateWidth() {
      self.progressBarTarget.style.width = `${widthObj.w}%`;
      self.progressUserIconTarget.style.left = `${self.progressBarTarget.clientWidth - self.remOffset}px`;
    }
  }
}