import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multiline-input"
export default class extends Controller {
  static values = {
    formId: String
  }

  connect() {
    // Submit form when enter is pressed
    this.element.addEventListener('keydown', ((event) => {
      if (event.keyCode == 13 && !event.shiftKey && this.formIdValue) {
        const form = document.getElementById(this.formIdValue)
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
          event.preventDefault();
          event.target.blur();
          return false;
        }
      }
    }));

    // Increase height to add new line when needed
    this.element.addEventListener('keyup', ((event) => {
      if (event.target.clientHeight !== event.target.scrollHeight) {
        event.target.style.height = (event.target.scrollHeight) + "px";
      }
    }));
  }
}
