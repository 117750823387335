import { Tabs } from "tailwindcss-stimulus-components"

// Connects to data-controller="search-tab"
export default class extends Tabs {
  static targets = ['blinker'];

  change(event) {
    super.change(event);
    // index is 1, then remove the blinker
    if (this.hasBlinkerTarget && parseInt(this.data.get('index') || 0) == 1) {
      this.blinkerTarget.classList.add('hidden');
    }
  }
}

