// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component_name in the component class.
//
import ScrollTrigger from "../../javascript/controllers/scroll_trigger_controller";
import { useDispatch } from "stimulus-use";

export class Controller extends ScrollTrigger {
  static targets = ['video'];

  connect() {
    super.connect();
    useDispatch(this);
  }

  action() {

    const modalElement = document.querySelector('[data-controller="modal"]');

    if (modalElement) {
      const event = new Event("open");
      modalElement.dispatchEvent(event);
      if (this.hasVideoTarget) {
        this.videoTarget.play();
      }
    }

  }
}
