import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-text"
export default class extends Controller {

  static targets = ['copyButton', 'copyTooltip'];

  static values = {
    content: String,
    beforeToolTipContent: String,
    afterToolTipContent: String
  }

  connect() {
    if (this.hasCopyButtonTarget) {
      this.copyButtonTarget.addEventListener('click', () => {
        this.copyTextToClipboard(this.hasContentValue ? this.contentValue : window.location.href);
      });
    }
  }

  fallbackCopyTextToClipboard(text) {

    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      if (this.hasCopyTooltipTarget) {
        this.copyTooltipTarget.innerHTML = this.hasAfterToolTipContentValue ? this.afterToolTipContentValue : "Link copied!"
        setTimeout(() => {
          this.copyTooltipTarget.innerHTML = this.hasBeforeToolTipContentValue ? this.beforeToolTipContentValue : "Copy link"
          this.copyTooltipTarget.classList.add("hidden")
        }, 2000);
      }
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  }
}
